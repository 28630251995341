/**
* 左边菜单
*/
<template>
	<el-menu default-active="2" :collapse="collapsed" collapse-transition router :default-active="$route.path"
		unique-opened class="el-menu-vertical-demo" background-color="#FFFFFF" text-color="#000000"
		active-text-color="#ffd04b">
		<div class="logobox">
			<img class="logoimg" src="../../assets/image/logo.png" alt="">

		</div>
		<el-submenu v-for="(menu,index) in allmenu" :key="index" :index="menu.menuname">
			<template slot="title">
				<i class="iconfont" :class="menu.icon"></i>
				<span>{{menu.menuname}}</span>
			</template>
			<el-menu-item-group>
				<el-menu-item v-for="(chmenu,cindex) in menu.menus" :index="'/system/'+chmenu.url" :key="cindex"
					@click="clickMenu(chmenu)">
					<i class="iconfont" :class="chmenu.icon"></i>
					<span>{{chmenu.menuname}}</span>
				</el-menu-item>
			</el-menu-item-group>
		</el-submenu>
	</el-menu>
</template>
<script>
	export default {
		name: 'leftnav',
		data() {
			return {
				collapsed: false,
				allmenu: []
			}
		},
		created() {
			let res = {
				success: true,
				data: [{
						menuid: 10,
						icon: 'li-icon-xitongguanli',
						menuname: '系统模版管理',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 14,
							icon: 'icon-order-manage',
							menuname: '系统模版分类',
							hasThird: 'N',
							url: 'design/tempsys/tempsyscls',
							menus: null
						}, {
							menuid: 11,
							icon: 'icon-order-manage',
							menuname: '已上架模版列表',
							hasThird: 'N',
							url: 'design/tempsys/tempsys',
							menus: null
						}, {
							menuid: 12,
							icon: 'icon-order-manage',
							menuname: '待审批模版列表',
							hasThird: 'N',
							url: 'design/tempsys/tempsyscheck',
							menus: null
						}, {
							menuid: 13,
							icon: 'icon-order-manage',
							menuname: '设计中模版列表',
							hasThird: 'N',
							url: 'design/tempsys/tempsysdesign',
							menus: null
						}]
					},
					{
						menuid: 20,
						icon: 'li-icon-xitongguanli',
						menuname: '用户模版管理',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 21,
							icon: 'icon-order-manage',
							menuname: '用户模版列表',
							hasThird: 'N',
							url: 'design/tempuser/tempuser',
							menus: null
						}]
					},
					{
						menuid: 30,
						icon: 'li-icon-xitongguanli',
						menuname: '模版设计配置',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 31,
							icon: 'icon-order-manage',
							menuname: '模版图标配置',
							hasThird: 'N',
							url: 'design/component/seticon',
							menus: null
						}, ]
					},
					{
						menuid: 40,
						icon: 'li-icon-xitongguanli',
						menuname: '模版风格配置',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 41,
							icon: 'icon-order-manage',
							menuname: '模版风格列表',
							hasThird: 'N',
							url: 'design/style/style',
							menus: null
						}, ]
					},
					{
						menuid: 50,
						icon: 'li-icon-xitongguanli',
						menuname: '企微',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 51,
							icon: 'icon-order-manage',
							menuname: '企微查询',
							hasThird: 'N',
							url: 'mp/wxwork/list',
							menus: null
						}, {
							menuid: 52,
							icon: 'icon-order-manage',
							menuname: '订单管理',
							hasThird: 'N',
							url: 'mp/wxwork/orderlist',
							menus: null
						}, {
							menuid: 53,
							icon: 'icon-order-manage',
							menuname: '企微授权',
							hasThird: 'N',
							url: 'mp/wxwork/license',
							menus: null
						},
						{
							menuid: 54,
							icon: 'icon-order-manage',
							menuname: '日志查询',
							hasThird: 'N',
							url: 'mp/wxwork/logs',
							menus: null
						}]
					},
					{
						menuid:60,
						icon: 'li-icon-xitongguanli',
						menuname: '系统',
						hasThird: null,
						url: null,
						menus: [{
							menuid: 60,
							icon: 'icon-order-manage',
							menuname: '成员管理',
							hasThird: 'N',
							url: 'sys/user',
							menus: null
						}
						]
					},
				],
				msg: 'success'
			}
			this.allmenu = res.data
			// 监听
			this.$root.Bus.$on('toggle', value => {
				this.collapsed = !value
			})
		},
		methods: {
			// 点击菜单
			clickMenu(value) {
				console.log(value, 'value');
				//通过vuex将数据存储在store中
				console.log(this.$store, 'this.$store');
				this.$store.commit('mutationSelectTags', value)
			}
		}
	}
</script>
<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 240px;
		min-height: 400px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
		text-align: left;
	}

	.el-menu-item-group__title {
		padding: 0px;
	}

	.el-menu-bg {
		background-color: #1f2d3d !important;
	}

	.el-menu {
		border: none;
	}

	.logobox {
		height: 40px;
		line-height: 40px;
		color: #9d9d9d;
		font-size: 20px;
		text-align: center;
		padding: 20px 0px;
	}

	.logoimg {
		height: 40px;
	}
</style>