import httpapi from "../../utils/httpapi.js"
const captchaApi = {
 //获取验证码
 createimg( funsucc) {
   
    httpapi.get('/common/captcha/create',{}).then(res => {
      console.log(res,'captcha')
      if (funsucc) {
        funsucc(res) 
      }

    }).catch({

    })

  },

}
export default captchaApi;