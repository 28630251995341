import axios from 'axios';
import util from './util.js'
axios.defaults.baseURL = '/api'
//内部通用请求-表单
function axiosform(method, url, params, istoken, role) {
  
  let header = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  // if (istoken) {
  //   header = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     token: localStorage.getItem('logintoken'),
	 //  // Authorization: 'Bearer ' +localStorage.getItem('logintoken'),
  //     role: role
  //   }
  // }
  
 let user = util.getloginuser()
  // let token = sessionStorage.getItem("token");
  if(user != null && user.token != undefined){
	  header = {
	    'Content-Type': 'application/x-www-form-urlencoded',
	     Authorization: "Bearer " + user.token,
	    role: role
	  }
  }
  
  return axios({
    method: method,
    url: url,
    headers: header,
    data: params,
    traditional: true,
    transformRequest: [
      function(data) {
        let ret = ''
        if(data != undefined){
          for (let it in data) {
            ret +=
              encodeURIComponent(it) +
              '=' +
              encodeURIComponent(data[it]) +
              '&'
          }
        }
        return ret
      }
    ]
  }).then(res =>{
	  if(res.data.code == 401){
		  
		  	  console.log(res,'401');
		  	  
	  	// window.location.href = "/"
	  }
	  return res.data
  });
}


//内部通用请求-json
function axiosjson(method, url, params, istoken, role) {
  let header = {
    'Content-Type': 'application/json'
  }
  // if (istoken) {
  //   header = {
  //     'Content-Type': 'application/json',
  //     token: localStorage.getItem('logintoken'),
  //     role: role
  //   }
  // }
  let user = util.getloginuser()
  // let token = sessionStorage.getItem("token");
  if(user!= null && user.token != undefined){
  	  header = {
  	    'Content-Type': 'application/json',
  	     Authorization: 'Bearer ' + user.token,
  	    role: role
  	  }
  }
 console.log(params,'axios.params');
 console.log(url,'axios.url');

  return axios({
    method: method,
    url: url,
    headers: header,
    data: params
  }).then(res => {
	  if(res.data.code == 401){
	  	// window.location.href = "/"
	  }
	  return res.data
  });
}

//上传图片
function handleUploadFile(method,url,formData) {
  let headers = {
       'Content-Type': 'multipart/form-data;charset=UTF-8'
    }
	let user = util.getloginuser()
	// let token = sessionStorage.getItem("token");
	if(user != null && user.token != undefined){
		  headers = {
		    'Content-Type': 'multipart/form-data;charset=UTF-8',
		     Authorization: 'Bearer ' + user.token,
		    
		  }
	}
    console.log(formData,'formData');
  return axios({
    url: url,
    method: method,
    headers:headers,
    data: formData,
    transformRequest:[function() {
      return formData;
    }]
  }).then(res=>res.data)
}

const httpapi = {
  // 登录请求方法
  //get表单请求无角色
  get(url, params) {
    return axiosform('GET', url, params, true, '')
  },
  //get 表单请求含角色
  getrole(url, params, istoken, role) {
    return axiosform('GET', url, params, istoken, role)
  },
  post(url, params) {
    return axiosform('POST', url, params, false, '')
  },
  postrole(url, params, istoken, role) {
    return axiosform('POST', url, params, istoken, role)
  },
  handleUploadFile(url,params){
    return handleUploadFile('POST', url, params, false,'')
  },
  postjson(url,params){
    return axiosjson('POST', url, params, false,'')
  },
  postjsonrole(url,params,role){
    return axiosjson('POST', url, params, true,role)
  }
}

export default httpapi
