/**
* 头部菜单
*/
<template>
	<div>
		<el-menu class="el-menu-demo" mode="horizontal" background-color="#FFFFFF" text-color="#000"
			active-text-color="#000">
			<el-button class="buttonimg">
				<img class="showimg" :src="collapsed?imgsq:imgshow" @click="toggle(collapsed)">
			</el-button>
			<el-submenu index="2" class="submenu">
				<template slot="title">{{userInfo.nickname}}</template>
				<el-menu-item index="2-1">设置</el-menu-item>
				<el-menu-item @click="content()" index="2-2">个人中心</el-menu-item>
				<el-menu-item @click="__pwd()" index="2-3">修改密码</el-menu-item>
				<el-menu-item @click="exit()">退出</el-menu-item>
			</el-submenu>
		</el-menu>
		<el-dialog title="修改账户密码" :visible.sync="priceVisible" width="30%" @click='priceVisible=false'>
			<div class="main">
				<el-input class="ipt" v-model="setdata.oldpwd" placeholder="请输入原密码" size="medium"></el-input>
				<el-input class="ipt" v-model="setdata.newpwd" placeholder="请输入新密码" size="medium"></el-input>
				<el-input class="ipt" v-model="setdata.newpwd2" placeholder="请输入确认密码" size="medium"></el-input>
				<div class="row">
					<el-input type="text" class="ipt2" size="medium" v-model="setdata.yzm" auto-complete="off"
						placeholder="请输入验证码"></el-input>
					<img :src="ruleForm.codeimg" class="yzm" @click="getcode"></img>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='priceVisible = false' style="margin-right: 20px;">取消</el-button>
				<el-button size="small" type="primary" style="margin-left: 20px;" @click='setpwd'>确定</el-button>
			</div>
		</el-dialog>
	</div>

</template>
<script>
	import captchaApi from '../../api/common/captcha.js'
	import userApi from '../../api/sys/tempuser.js'
	import {
		getloginuser
	} from '../../utils/util.js'
	export default {
		name: 'navcon',
		data() {
			return {
				collapsed: true,
				imgshow: require('../../assets/img/show.png'),
				imgsq: require('../../assets/img/sq.png'),
				userInfo: {},
				priceVisible: false,
				ruleForm: {
					uuid: '',
					codeimg: ''
				},
				setdata: {
					oldpwd: '',
					newpwd: '',
					newpwd2: '',
					yzm:''
				}
			}
		},
		// 创建完毕状态(里面是操作)
		created() {
			this.init();

		},
		methods: {
			init() {
				// let u = sessionStorage.getItem('systemuser')
				let u = getloginuser()
				if (u != null) {
					this.userInfo = u;
				} else {
					this.$router.push("/");
				}
			},
			// 退出登录
			exit() {
				this.$confirm('退出登录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						setTimeout(() => {
							this.$router.push({
								path: '/'
							})
							delCookie("login_uid");
							sessionStorage.clear()
							this.$message({
								type: 'success',
								message: '已退出登录!'
							})
						}, 1000)
					})
					.catch(() => {

					})
			},
			// 切换显示
			toggle(showtype) {
				this.collapsed = !showtype
				this.$root.Bus.$emit('toggle', this.collapsed)
			},
			__pwd() {
				this.priceVisible = true
				this.setdata.oldpwd = ''
				this.setdata.newpwd = ''
				this.setdata.newpwd2 = ''
				this.setdata.yzm = ''
				this.getcode()

			},
			setpwd() {
				let _this = this
				if(_this.setdata.newpwd != _this.setdata.newpwd2){
					this.$message({
						message:'确认密码与新密码不一致，请重新输入',
						type:'error'
					})
					return
				}
				if(_this.setdata.yzm.length < 4){
					this.$message({
						message:'请输入验证码',
						type:'error'
					})
					return
				}
				let param = {
					newpwd:_this.setdata.newpwd,
					oldpwd:_this.setdata.oldpwd,
					uuid:_this.ruleForm.uuid,
					yzm:_this.setdata.yzm
				}
				userApi.modifypwd(param,res =>{
						console.log(res,'resss');
					if(res.code == 200){
						_this.$message({
							message:'修改成功',
							type:'success'
						})
						_this.priceVisible = false
					}else{
						_this.$message({
							message:res.msg,
							type:'error'
						})
						_this.getcode()
					}
				})
			},
			getcode() {
				var _this = this
				captchaApi.createimg(res => {
					console.log(res, 'getcode.res');
					if (res.code == 200) {
						_this.ruleForm.codeimg = `data:image/png;base64,${res.img}`
						_this.ruleForm.uuid = res.uuid
					} else {
						_this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
		}
	}
</script>
<style scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
	}

	.submenu {
		float: right;
	}

	.buttonimg {
		height: 60px;
		background-color: transparent;
		border: none;
	}

	.showimg {
		width: 26px;
		height: 26px;
		position: absolute;
		top: 17px;
		left: 17px;
	}

	.showimg:active {
		border: none;
	}

	.main {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.row {
		display: flex;
		align-items: center;
	}

	.yzm {
		width: 120px;
		height: 40px;
		margin-left: 20px;
		cursor: pointer;
	}

	.ipt {
		width: 300px;
		margin: 15px 0;
	}

	.ipt2 {
		width: 160px;
	}
	.dialog-footer{
		display: flex;
		justify-content: center;
	}
</style>