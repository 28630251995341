import httpapi from '../../utils/httpapi';
const tempApi = {
	//用户模版列表
	list(param, funsucc) {
		httpapi.post('/system/design/usertmplate/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//编辑页面的风格
	editstyle(param, funsucc) {
		httpapi.post('/system/design/usertmplate/editstyle', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//用户程序模版更换系统模版
	changetemplate(param, funsucc) {
		httpapi.post('/system/design/usertmplate/changetemplate', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	modifypwd(param, funsucc) {
		httpapi.post('/system/auth/user/modifypwd', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	
}
export default tempApi